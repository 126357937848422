@tailwind components;
@tailwind base;

@layer base {
  * {
    @apply font-sans;
  }

  .page-section {
    @apply pt-[100px] h-screen flex flex-col justify-center items-center;
  }

  /*
  **
  ** Scroll Bar
  **
  **
  */
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    opacity: 1;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
    border-radius: 10px;
  }
  
  .custom-scrollbar::-webkit-scrollbar {
    @apply bg-k1475-lightest;
    width: 12px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    @apply bg-k1475-dark;
    border-radius: 10px;
    box-shadow: inset 0 0 2px rgba(0,0,0,.3);
  }
}

@layer components {
  .custom-logo {
    @apply mr-8 animate-pulse;
  }

  .custom-button {
    @apply 
      whitespace-nowrap 
      inline-flex 
      items-center 
      justify-center 
      px-8
      py-2
      border 
      border-transparent 
      bg-k1475-darker
      rounded-full 
      shadow-sm 
      text-white
      text-xl 
      opacity-75 
      hover:opacity-100;
  }

  .custom-button[data-loading="true"] {
    @apply !pr-0;
  }

  .custom-button-arrow {
    @apply 
      ml-4 
      h-10
      w-10
      text-gray-500
      inline-block
      mix-blend-multiply;
  }

  .custom-button-spin {
    @apply 
      !mx-4
      h-10
      w-10
      text-gray-500
      inline-block;
  }

  .custom-nav-link {
    @apply text-2xl md:text-xl lg:mr-8 px-3 py-2 rounded-xl bg-k1475-dark bg-opacity-0 transition-all hover:bg-opacity-100 duration-500;
  }

  .custom-popup-link {
    @apply text-2xl text-center py-2 rounded-xl bg-k1475-dark bg-opacity-0 transition-all hover:bg-opacity-100 duration-500;
  }

  /* .custom-nav-link:hover,
  .custom-popup-link:hover {
    @apply 
      underline 
      decoration-2 
      underline-offset-4;
  } 

  .custom-popup-link:hover {
    @apply bg-white;
  } */
}